let initialized = false;

function initialize(userID) {
  initialized = true;

  const { credere: showroomID, store: storeID } = window.CREDERE._INTERNAL?.gtag || {};
  const embedID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;

  const internalIDs = [embedID, showroomID].filter((i) => i);
  const externalIDs = internalIDs.length ? [storeID].filter((i) => i) : [];

  const allIDs = internalIDs.concat(externalIDs);

  if (!allIDs.length) {
    return;
  }

  const element = document.createElement('script');
  element.async = true;
  element.src = `https://www.googletagmanager.com/gtag/js?id=${internalIDs[0]}`;

  document.body.append(element);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.allowlist': ['google'] });

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }

  gtag('js', new Date());

  allIDs.forEach((id) => {
    const internal = internalIDs.includes(id);
    const { referrer } = window.CREDERE._INTERNAL || {};

    gtag('config', id, {
      send_page_view: false,
      user_id: (showroomID || !internal) ? undefined : userID,
      embed_parent: internal ? referrer : undefined,
    });
  });

  window.gtag = gtag;
}

export default function googleAnalytics(to, userID) {
  if (!window.gtag && !initialized) {
    initialize(userID);
  }

  window.gtag?.('event', 'page_view');
}
