import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { endpoint } from '@js/dom/parent';

import sentry from '@js/clients/sentry';
import referrer from '@js/settings/referrer';

import App from './App.vue';

import routers from './routers';

import '@js/clients/fontawesome';
import '@css/app.scss';
import '@css/tokens.scss';

window.CREDERE = window.CREDERE || {};
window.CREDERE._INTERNAL = window.CREDERE._INTERNAL || {};

let app;

const ELEMENT = '#credere-embed-app';

function initialize() {
  const { CREDERE } = window;

  try {
    app.unmount();
  } catch (err) { /* */ }

  const parent = document.querySelector(CREDERE.element || ELEMENT);
  if (!parent) { return; }

  referrer();
  const _endpoint = endpoint();

  app = createApp(App);
  sentry(app, routers.app);

  app.component('font-awesome-icon', FontAwesomeIcon);

  app.use(createPinia());
  app.use(routers.app);

  app.mount(parent);

  if (_endpoint) {
    routers.app.replace(`/${_endpoint}`);
  }

  window.CREDERE_EMBED = app;
}

window.CREDERE.reinitialize = initialize;

initialize();
