export default function contrast(value = '#FFFFFF') {
  let values;

  if (/#/.test(value)) {
    values = value.replace('#', '').match(/.{2}/g).map((i) => parseInt(i, 16));
  } else if (/rgb/.test(value)) {
    values = value.replace(/rbg\(| |\)/g, '').split(',');
  } else {
    return true;
  }

  return ((values[0] * 299 + values[1] * 587 + values[2] * 114) / 1000) >= 128;
}
