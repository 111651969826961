import * as Sentry from '@sentry/vue';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export default function sentry(app, router) {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
  });
}
