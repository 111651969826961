// import MESSAGE from '@js/messages';
import contrast from '@js/misc/contrast';

const ID = 'credere-tokens';

const PRIMARY = '--token-color-brand-primary';
const CONTRAST = '--token-color-brand-contrast';

function brand() {
  const root = document.querySelector(':root');
  const contrasted = contrast(window.getComputedStyle(root).getPropertyValue(PRIMARY))
    ? 'var(--token-color-constant-dark)'
    : 'var(--token-color-constant-light)';

  root.style.setProperty(CONTRAST, contrasted);

  // MESSAGE.TOKEN({
  //   name: CONTRAST,
  //   value: contrasted,
  // });
}

export function sanitize(string) {
  return string.replace(/[<]/g).replace(/:root/g, '.product--embed');
}

export function stylize(string = '') {
  const existing = document.getElementById(ID);
  const tag = existing || document.createElement('style');

  tag.textContent = sanitize(string);

  if (!existing) {
    tag.id = ID;
    document.body.append(tag);
  }

  brand();
}
