<script setup>
  import {
    computed,
    onMounted,
    onBeforeUnmount,
    ref,
    watch,
    defineAsyncComponent,
  } from 'vue';

  import { storeToRefs } from 'pinia';
  import { useRoute, RouterView } from 'vue-router';

  import MESSAGE from '@js/messages';

  import useAuthStore from '@/stores/auth';

  const ShowroomBanner = defineAsyncComponent(() => import('@/views/Showroom/Banner.vue'));
  const ShowroomSocials = defineAsyncComponent(() => import('@/views/Showroom/Socials.vue'));

  const route = useRoute();
  const refRoute = ref();

  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  const key = ref(0);
  watch(key, MESSAGE.SCROLL);
  function reload() { key.value += 1; }

  const { layout } = window.CREDERE;
  const _banner = computed(() => layout?._enable_top_banner);
  const _socials = computed(() => layout?._enable_social_banner);

  function resize() {
    if (/^\/preview/.test(window.location.pathname)) {
      return;
    }

    MESSAGE.RESIZE({ height: document.body.clientHeight + 1 });
  }

  const resizeObserver = new ResizeObserver(() => {
    setTimeout(() => resize(), 0);
  });

  const classes = computed(() => ({
    'credere-route': true,
  }));

  onMounted(() => {
    resizeObserver.observe(document.body);
  });

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
  });
</script>

<template>
  <div
    class="credere-app"
    v-if="user.id || route.meta.public"
  >
    <showroom-banner v-if="_banner" />

    <router-view
      ref="refRoute"
      :key="key"
      :class="classes"
      @reload="reload"
    />

    <showroom-socials v-if="_socials" />
  </div>
</template>

<style lang="scss">
  .credere-app {
    --background-color: var(--token-color-constant-light);

    container-type: inline-size;
  }

  .credere-route {
    position: relative;
  }
</style>
