import { ref } from 'vue';
import { defineStore } from 'pinia';

export default defineStore('auth', () => {
  const environment = ref();
  const authorization = ref();

  const user = ref({});
  const scope = ref([]);

  function prepare() {
    environment.value = window.CREDERE.environment;
    authorization.value = window.CREDERE.authorization;
  }

  function clean(entity = {}) {
    return {
      id: entity.id,
      cpf: entity.cpf,
      name: entity.name,
      account: {
        id: entity.account?.id,
        name: entity.account?.name,
      },
      role: {
        id: entity.role?.id,
        name: entity.role?.name,
        identifier: entity.role?.identifier,
      },
    };
  }

  function overwrite(entity = {}) {
    user.value = clean(entity);
    scope.value = entity?.current_token_scopes?.split(',') || '';
  }

  prepare();

  return {
    environment,
    authorization,
    user,
    scope,

    prepare,
    overwrite,
  };
});
